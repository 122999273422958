import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from "react-router";
import { Link, useLocation } from "react-router-dom";
import '../../../assets/css/dashboard.css';
import '../../../assets/css/intro.css';
import '../../../assets/css/nanak.css';
import Axios from 'axios';
import { API } from "../../../config/api";
import { ApiHelper } from '../../../helpers/ApiHelper';
//import imgs from './assets/img/content/ggs_01.jpg'
import introbannar from '../../../assets/img/intro-bannar.webp';
import Spinner from '../../../components/Spinner';

import gobind12 from '../../../assets/img/content/gobind12.jpg';
import gobind4 from '../../../assets/img/content/gobind4.jpg';
import gobind17 from '../../../assets/img/content/gobind17.jpg';

function GuruGobind6() {
    const [datas, setDatas] = useState([]);
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(false);
    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        await Axios.get('https://backend.searchgurbani.com/api/meta?url=gurus/gurugobind6')
            .then((resData) => {
                console.log('INTRO', resData.data.data);
                setDatas(resData.data.data)

            })
            .catch((err) => {
                console.log(err);
            })
    }

    return (
        <div>
            <section className='section-1'>
                <div className=" justify-content-md-center align-items-center">
                    <div className='banner-img'>
                        <img src={introbannar} class="img-fluid" alt="Responsive image" />
                    </div>
                </div>

                <div className='Gurbani-Raags p-4'>
                    <div className='container'>
                        <h1 class="inner-heading "></h1>
                        <div className="row ">
                            <div class="col-lg-12">
                                <div class="px-1 py-1 align-middle mt-0 akl-intro">
                                    
                               
                                {datas ?

                                    < div className='dates'
                                        dangerouslySetInnerHTML={{
                                            __html: datas.html
                                                ?.replace('src=\"assets\/img\/res\/gobind12.jpg\"', `src="${gobind12}"`)
                                                .replace('src=\"assets\/img\/res\/gobind4.jpg\"', `src="${gobind4}"`)
                                                .replace('src=\"assets\/img\/res\/gobind17.jpg\"', `src="${gobind17}"`)
                                        }}
                                    /> : null}
                            </div>
                        </div>
                    </div>
                    <hr></hr>
                <div className='page-content right-algn-next'><a href=''>Next</a></div>
                <div className='page-content left-algn-next'><a href=''>Back</a></div>

                </div>
        </div>

                

    { loader && <Spinner /> }
            </section >

        </div >
    )
}

export default GuruGobind6