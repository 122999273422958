//const BASEURL = 'https://backend.searchgurbani.com/api/' 
const BASEURL ='https://backend.searchgurbani.com/api/'

export const API = {
    //common
    baseUrl: BASEURL,
    register: BASEURL + 'user/register',
    login: BASEURL + 'user/login',
    getAngByAng: BASEURL + 'get_ang_by_ang',
    getGgsVerse: BASEURL + 'guru-granth-sahib/verse',
    getAngByAngAudio: BASEURL + 'audio/download',
    getGgsChapter: BASEURL + 'ggs/get_chapters',
    getggsSubChapter: BASEURL + 'ggs/get_shabadEs_chapters/',
    getAuthor: BASEURL + 'ggs/get_authors',
    getAuthorRaga: BASEURL + 'ggs/get_author',
    getAngWorld: BASEURL + 'guru-granth-sahib/world/ang',
    getTranslations: BASEURL + 'guru-granth-sahib/world/ang/translations',
    getTrasliterations: BASEURL + 'guru-granth-sahib/world/ang/transliterations',
    getAllSearchWords: BASEURL + 'get_allwords',
    getRagas: BASEURL + 'ggs/get_raags',
    getSearchData: BASEURL + 'get_datatable_data',
    getAllCategory: BASEURL + 'bhai-nand-lal/get_all_category',
    getShabadLine: BASEURL + 'ggs/shabad/',
    getAkPagebyPage: BASEURL + 'ak/page',
    getAkEnglishIndex: BASEURL + 'ak/index/',
    getAkHindiIndex: BASEURL + 'ak/index/',
    getAkShabadLine: BASEURL + 'ak/shabad/',
    getAkChapterIndex: BASEURL + 'ak/index/chapter',
    getChapterName: BASEURL + 'ak/chapter/',
    getPauruByPauri: BASEURL + 'bgv/vaar',
    getVaarIndex: BASEURL + 'bgv/index/vaar',
    getVaarPauri: BASEURL + 'bgv/vaar/',
    getDgPageByPage: BASEURL + 'dasam-granth/page',
    getDgChapterIndex: BASEURL + 'dasam-granth/index/chapter',
    getDgShabadLine: BASEURL + 'dasam-granth/shabad/',
    getDgVerse: BASEURL + 'dasam-granth/verse',
    getKabitByKabit: BASEURL + 'kabit-savaiye/kabit',
    getBnlPages: BASEURL + 'bhai-nand-lal/',
    getBnlVerse: BASEURL + 'bhai-nand-lal/verse',
    getBaanis: BASEURL + 'baanis/',
    getGutka: BASEURL + 'sundar/gutka',
    getResourceWords: BASEURL + 'res/get_resources_words',
    getResourceResult: BASEURL + 'res/mahan-kosh/view',
    getSGGKresult: BASEURL + 'res/guru-granth-kosh/view',
    getSggskResult: BASEURL + 'res/sggs-kosh/view',
    getMaansarovarWords: BASEURL + 'res/maansarovar/word',
    getMaansarovarIndex: BASEURL + 'res/maansarovar/quotations/',
    getSNPChapter: BASEURL + 'res/sri-nanak-prakash/chapters/',
    getSNPViewPage: BASEURL + 'res/sri-nanak-prakash/page',
    getSGPSGSearch: BASEURL + 'res/sri-gur-pratap-suraj-granth/volumes',
    getSGPSGIndex: BASEURL + 'res/sri-gur-pratap-suraj-granth/chapters',
    getResPage: BASEURL + 'res/',
    getFWTIndex: BASEURL + 'res/faridkot-wala-teeka/chapters',
    getFWTSearchPreview: BASEURL + 'res/faridkot-wala-teeka/search-preview',
    getSNPSearchPreview: BASEURL + 'res/sri-nanak-prakash/search-preview',
    getSGPSGSearchPreview: BASEURL + 'res/sri-gur-pratap-suraj-granth/search-preview',
    getResSearchResultPreview: BASEURL + 'scriptures/search-results-preview',
    getHukumIndex: BASEURL + 'res/hukumnama',
    getCyberHukum: BASEURL + 'res/hukumnama/ang',
    getHukumnama: BASEURL + 'res/hukum',
    getMusicIndex: BASEURL + 'music/page',
    getCompilation: BASEURL + 'compilation/page',
   // getGSPSGView: BASEURL + 'res/sri-gur-pratap-suraj-granth/page',

}
//https://backend.searchgurbani.com/api/kabit-savaiye/kabit?page=1&lineno=4
//https://data.searchgurbani.com/api/ak/chapter/1/dho-e-kar-jor-karo-ardaas